/* InputBox.css */

@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .animateRecording {
    animation: pulse 1s infinite;
  }
  